<script>
  import { Card, Button, Select, Textarea} from "flowbite-svelte";
	import { marked } from 'marked';
  import { blur } from "svelte/transition";
  import { onMount, tick } from "svelte";
  import { 
    create_chat_conversation_message, 
    list_chat_conversation_message,
    clear_chat_conversation_message 
  } from "./service";
  import FileHandler from "../components/FileHandler.svelte";

  const models = [
    {name: "General (ChatGPT-4o)", value: "gpt-4o"}, 
    {name: "Internet (Perplexity)", value: "perplexity"},
    {name: "Other searches (Gemini)", value: "gemini-1.5-pro"}
  ];
  
  let is_writing = false;
  let welcome_message = {message: 'Hi! How can I assist you today?', role: 'AI'};
  let messages = [];
  let message = "";
  let selectedModel = models[0].value;
  let attachment = null;


  let container;
  function scrollToBottom() {
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth'
    });
  }

  let handle_clear_history = async () =>{
    await clear_chat_conversation_message()
      .then(()=>{
        messages = [];
      });
  };


  let send_message_helper = async () => {
    if (message.trim() === "" && !attachment) return;

    messages = [...messages, {message: message, role: "HUMAN" }];
    tick().then(scrollToBottom);

    is_writing = true;
    tick().then(scrollToBottom);

    let body = {
      message,
      model: selectedModel,
    };

    await create_chat_conversation_message(body)
      .then((response)=>{
        // is_writing = false;
        messages = [...messages, response];
        tick().then(scrollToBottom);
        message = "";
      })
      .finally(()=>{
        is_writing = false;
      })
  }

  let handleKeyPress = async (event) => {
      if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          await send_message_helper();
      }
  }

  onMount(async () => {
    await tick(); // Ensure DOM is updated

    await list_chat_conversation_message().then((response) => {
      messages = [welcome_message, ...response];
      scrollToBottom();
    });
  });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="w-full h-screen">

            <!-- Main Chat Area -->
            <div class="flex flex-col flex-1">
                <div class="mb-4 flex">
                  <Select bind:value={selectedModel} items={models} class="w-min"/>
                </div>
                <div class="flex-1 overflow-y-auto bg-gray-50 rounded-md p-4 space-y-2 message-container" bind:this={container}>
                    {#each messages as { message, role }}
                      <div
                        transition:blur
                        class="bubble {role === 'AI'
                          ? 'ai-message'
                          : 'user-message'}"
                      >
                        <div class="prose">
                            {@html marked(message)}
                        </div>
                      </div>
                    {/each}
                    {#if is_writing}
                      <div in:blur class="bubble ai-message">
                        <span >•••</span>
                      </div>
                    {/if}
                </div>
                <div class="mt-4 mb-40">
                  <Textarea
                  bind:value={message}
                  on:keydown={handleKeyPress}
                  disabled={is_writing}
                  placeholder="Type a message..."
                  ></Textarea>
                  <div class="w-full justify-end flex gap-x-2">
                    <Button color="red" on:click={handle_clear_history}>Clear All</Button>
                    <Button color="purple" on:click={send_message_helper} disabled={is_writing}>Submit</Button>
                  </div>
                  <FileHandler module="chat" />
                </div>
            </div>
        </Card>
    </div>
</div>



<style>
	/* Define styles for the scrollable container */
	.message-container {
		display: flex;
		flex-direction: column;
		flex: 1; /* Grow to fill available space */
	}

	/* Styles for conversation bubbles */
	.bubble {
		max-width: 95%; /* Limit the width of bubbles */
		margin-bottom: 10px;
		padding: 10px;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 30px;
		word-wrap: break-word;
	}

	/* Styles for user messages */
	.user-message {
		align-self: flex-end; /* Align to the right */
		background-color: #DAEBFE; 
		color: #585E6D;
    border-bottom-right-radius: 0px;
	}

	/* Styles for AI messages */
	.ai-message {
		align-self: flex-start; /* Align to the left */
		background-color: #ffffff;
    border-bottom-left-radius: 0px;
	}

  div.flex-1 {
      min-height: 0;
  }
</style>