// notify.js

import { writable } from "svelte/store";
import { createLocalStorageStore } from "./store";




// Initial store state for notification form
export const initialState = {
    // Date variables
    startDate: null,
    endDate: null,
    notificationPeriodStartDate: null,
    notificationPeriodEndDate: null,
    threeyrsBeforeNotificationEnd: null,

    // Previous claim variables
    isFirstClaim: "yes",
    previousClaimType: "",
    wasRejected: "",
    previousClaimStartDate: null,
    previousClaimSubmissionDate: null,

    // Output variables 
    showOutput: false,
    needsPrenotification: false,

    // Validation errors
    endDateError: "",
    prevStartDateError: "",
    submissionDateError: "",

    // Project description variables
    "project_description": {
        option_1: {
            content: "No content",
            loading: false,
            user_instructions: "",
            result_id: "",
            add_instructions_modal_open: false,
        },
        option_2: {
            content: "No content",
            loading: false,
            user_instructions: "",
            result_id: "",
            add_instructions_modal_open: false,
        },
},
};

// Create persistent store using local storage
export const notifyStore = createLocalStorageStore("notify", initialState);

export const clear_all_modal_open_notify = writable(false);
