<script>
    import { toast } from "@zerodevx/svelte-toast";
    import {
        Button,
        Card,
        Checkbox,
        Dropdown,
        DropdownItem,
        Hr,
        Label,
        Modal,
        P,
        Range,
        Select,
        Spinner,
        Textarea,
    } from "flowbite-svelte";
    import { ChevronDownOutline } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import { onMount } from "svelte";
    import {
        ask_llm,
        askAssistant,
        deleteAllFiles,
        download_file,
        fetchFiles,
    } from "../service/http_calls.js";
    import {
        ajust_response_modal_open,
        fact_checks_modal_open,
        files_list,
        general_prompt,
        initial_questions,
        loading_compile,
        paragraph_to_regenerate,
        questions,
        regenerate_paragraph_modal_open,
        regenerated_paragraph,
        review,
        selected_question,
        source_sentence_modal_open,
        source_sentence_questions_modal_open,
        sources,
        user_instructions_to_regenerate_paragraph,
    } from "../service/store.js";
    import Compile from "./Compile.svelte";
    import FilesTable from "./FilesTable.svelte";
    let general_prompt_edit = false;
    let file_table_loading = false;
    let downloading_file = false;
    let clearResponsesModal = false;
    let clear_instructions = false;
    onMount(async () => {
        file_table_loading = true;
        const resp = await fetchFiles("draft");
        file_table_loading = false;

        files_list.update((prev) => {
            if (Array.isArray(resp)) {
                prev["draft"] = resp;
            }
            return prev;
        });
    });

    async function handleCompile() {
        loading_compile.set(true);

        // set all questions to processing
        questions.update((prev) => {
            for (let key in prev) {
                prev[key].response.status = "processing";
            }
            return prev;
        });

        for (let key in $questions) {
            console.log("Asking for " + key);

            const depenency_request_ids = $questions[key].dependencies
                .map((dependency) => $questions[dependency]?.request_id)
                .filter((id) => id !== undefined);

            let resp = await askAssistant(
                $general_prompt,
                $questions[key].prompt,
                key,
                "draft",
                depenency_request_ids,
            );
            if (resp.status == "rejected") {
                toast.push(resp.reason);
            } else {
                questions.update((prev) => {
                    prev[key].request_id = resp.request_id;
                    prev[key].result_id = resp.result_id;
                    return prev;
                });
            }
        }
        toast.push("Requests submitted");

        // toast.push("Compiled successfully");
    }
    let narrative_voice = [
        { value: "First", name: "First Person" },
        { value: "Third", name: "Third Person" },
    ];
    let tense_list = [
        { name: "Past", value: "Past" },
        { name: "Present", value: "Present" },
        { name: "Future", value: "Future" },
    ];

    let current_length = 200;
    let current_tone = 2;
    let current_detail_level = 2;
    let current_vocabulary = 2;

    let detail_level_dict = {
        1: "High Level",
        2: "Mid Level",
        3: "Detailed",
    };
    let vocabulary_dict = {
        1: "Simple",
        2: "Mid (not too technical)",
        3: "Technical",
    };
    let tone_dict = {
        1: "Informal",
        2: "Not too formal",
        3: "Formal",
    };

    async function handleDownload(doc_type) {
        downloading_file = true;
        let payload = {};
        Object.entries($questions).forEach(([key, value]) => {
            payload[key] = value.response?.content;
        });

        let title = $questions["q_1"]?.response?.content;
        await download_file(payload, doc_type, "draft", title);
        downloading_file = false;
    }
    $: submit_disabled =
        $files_list["draft"].length == 0 ||
        $files_list["draft"].some((file) => file.status !== "Ready");

    let regenerate_paragraph_loading = false;
    async function handleRegenerateParagraph(question) {
        regenerate_paragraph_loading = true;
        let resp = await ask_llm(
            "DONT EXTEND THE ORIGINAL TEXT UNLESS EXPLICITLY INSTRUCTED TO DO SO. My instructions are: " +
                $user_instructions_to_regenerate_paragraph +
                "\n\ THE ORIGINAL TEXT IS: " +
                $paragraph_to_regenerate,
            "draft",
            "overall",
            "paragraph_regeneration",
            "gpt-4o",
        );
        regenerated_paragraph.set(resp.generated_content.replace(/`/g, ""));
        regenerate_paragraph_loading = false;
    }

    async function handleCopyToReview() {
        review.update((prev) => {
            prev.user_instructions.baseline =
                $questions["q_3"]?.response?.content;
            prev.user_instructions.advance =
                $questions["q_4"]?.response?.content;
            prev.user_instructions.uncertainty =
                $questions["q_5"]?.response?.content;
            prev.user_instructions.resolution =
                $questions["q_6"]?.response?.content;

            return prev;
        });
        window.location.href = "/review";

        toast.push("Copied to review!");
    }
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            {#if file_table_loading}
                <div class="flex justify-center">
                    <Spinner></Spinner>
                </div>
            {:else}
                <FilesTable module="draft"></FilesTable>
            {/if}

            <Hr classHr="my-8" />

            <div class="mb-10">
                <div class="mt-5 flex justify-end">
                    <Button
                        size="sm"
                        color="alternative"
                        class="mr-5"
                        on:click={() => {
                            general_prompt_edit = true;
                        }}>Add Instructions</Button
                    >
                    {#if $loading_compile}
                        <Spinner></Spinner>
                    {:else}
                        <Button
                            color="purple"
                            size="sm"
                            disabled={submit_disabled}
                            on:click={handleCompile}>Submit</Button
                        >
                    {/if}
                </div>
                <Compile></Compile>
            </div>

            <div class="flex justify-end">
                <Button
                    color="alternative"
                    class="mr-5"
                    on:click={handleCopyToReview}
                >
                    Copy To Review
                </Button>

                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >
                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = Object.entries($questions)
                                    .map(
                                        ([key, value]) =>
                                            `${value.title}\n${value.response?.content}`,
                                    )
                                    .reduce((acc, str) => acc + "\n\n" + str);
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
<Modal
    title="Add General Instructions"
    bind:open={general_prompt_edit}
    autoclose
>
    <Textarea rows="5" bind:value={$general_prompt}></Textarea>
    <div class="flex justify-end">
        <Button
            on:click={() => {
                general_prompt_edit = false;
            }}>Save</Button
        >
    </div>
</Modal>

<Modal title="Source" bind:open={$source_sentence_modal_open}>
    <div class="max-w-full prose">
        {@html marked($sources)}
    </div>
</Modal>
<Modal title="Fact Checks" bind:open={$fact_checks_modal_open}>
    <div class="max-w-full prose">
        {#if $questions[$selected_question]?.response?.fact_checks}
            {@html marked(
                $questions[$selected_question]?.response?.fact_checks,
            )}
        {:else}
            <p>No fact checks found</p>
        {/if}
    </div>
</Modal>

<Modal title="Source" bind:open={$source_sentence_questions_modal_open}>
    {#if $sources}
        <div class="max-w-full prose">
            {@html marked($sources)}
        </div>
    {:else}
        <p>No sources found</p>
    {/if}
</Modal>

<Modal title="Adjust Output" bind:open={$ajust_response_modal_open}>
    <div class="max-w-full">
        <Label class="mb-5">
            Narrative Voice
            <Select class="mt-2" items={narrative_voice} value={"Third"} />
        </Label>
        <Label class="mb-5">
            Tense
            <Select class="mt-2" items={tense_list} value={"Past"} />
        </Label>
        <Label>Length: {current_length}</Label>
        <Range
            min="100"
            max="500"
            bind:value={current_length}
            step={100}
            class="mb-5"
        />
        <Label>Tone: {tone_dict[current_tone]}</Label>
        <Range
            min="1"
            max="3"
            bind:value={current_tone}
            step={1}
            class="mb-5"
        />
        <Label>Detail Level: {detail_level_dict[current_detail_level]}</Label>
        <Range
            min="1"
            max="3"
            bind:value={current_detail_level}
            step={1}
            class="mb-5"
        />
        <Label>Vocabulary Level: {vocabulary_dict[current_vocabulary]}</Label>
        <Range min="1" max="3" bind:value={current_vocabulary} step={1} />
    </div>
</Modal>
<Modal title="Clear All" bind:open={clearResponsesModal} autoclose>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all files and outputs?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone.
    </p>
    <svelte:fragment slot="footer">
        <Checkbox bind:checked={clear_instructions}
            >Clear All Instructions</Checkbox
        >
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                questions.update((prev) => {
                    return initial_questions;
                });
                if (clear_instructions) {
                    general_prompt.set("");
                    Object.keys($questions).forEach((key) => {
                        $questions[key].prompt = "";
                    });
                }
                await deleteAllFiles("draft");
                files_list.update((prev) => {
                    prev["draft"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>

<Modal
    title="Regenerate Paragraph"
    bind:open={$regenerate_paragraph_modal_open}
>
    <div class="max-w-full prose">
        {@html marked($paragraph_to_regenerate)}
    </div>

    <Label>Instructions</Label>
    <Textarea bind:value={$user_instructions_to_regenerate_paragraph}
    ></Textarea>

    {#if $regenerated_paragraph}
        <Label>Regenerated Paragraph</Label>
        <div class="prose max-w-full">
            {@html marked($regenerated_paragraph)}
        </div>
    {/if}

    <svelte:fragment slot="footer">
        {#if regenerate_paragraph_loading}
            <div class="flex justify-center">
                <Spinner></Spinner>
            </div>
        {:else}
            {#if $regenerated_paragraph}
                <Button
                    color="purple"
                    on:click={() => {
                        // find the paragraph in the question and replace it with the regenerated paragraph
                        questions.update((prev) => {
                            prev[$selected_question].response.content = prev[
                                $selected_question
                            ].response.content.replace(
                                $paragraph_to_regenerate,
                                $regenerated_paragraph,
                            );
                            return prev;
                        });
                        regenerate_paragraph_modal_open.set(false);
                    }}>Submit Changes</Button
                >
            {/if}
            <Button
                color="purple"
                on:click={async () => {
                    await handleRegenerateParagraph();
                }}>Regenerate</Button
            >
            <Button
                color="alternative"
                on:click={() => regenerate_paragraph_modal_open.set(false)}
                >Cancel</Button
            >
        {/if}
    </svelte:fragment>
</Modal>
