<script>
    import { toast } from "@zerodevx/svelte-toast";
 
    // Importing necessary components from svelte-flowbite
    import { Button, Label, Input, Spinner } from 'flowbite-svelte';
    import { register } from "../service/http_calls";
 
    let email = "";
    let password = "";
    let confirmPassword = "";
    let loading = false;
    async function handleSignup(event) {
       event.preventDefault();
 
       // Basic client-side validation
       if (password !== confirmPassword) {
          toast.push("Incorrect password, please check", { type: 'error' });
          return;
       }
 
       // Prepare form data
 
       try {
          loading = true;

          const response = await register(email, password);
         loading = false;
          if (response.status === "OK") {
             toast.push("Signup successful, please log in.", { type: 'success' });
             // Optionally, redirect to login page
             window.location.href = ('/login');
          } else {
             toast.push(response.detail.message, { classes: ["warn"] });
          }
       } catch (error) {
          toast.push("An error occurred. Please try again later.", { classes: ["warn"] });
          console.error(error);
       }
    }
 
    // Function to detect 'Enter' key press
    function handleKeyDown(event) {
       if (event.key === "Enter") {
          handleSignup(event);
       }
    }
 </script>
 
 <!-- Container for the signup form -->
 <div class="flex justify-center items-center min-h-screen bg-gray-100">
   <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h2 class="text-2xl font-semibold text-center mb-6">Sign Up</h2>
 
      <!-- Signup Form -->
      <form on:submit|preventDefault={handleSignup}>
         <!-- Email Field -->
         <div class="mb-4">
            <Label for="email" value="Email" class="block mb-2 text-sm font-medium text-gray-700" />
            <Input 
               type="email" 
               bind:value={email} 
               placeholder="Enter your email" 
               class="w-full" 
               on:keydown={handleKeyDown} 
               required 
            />
         </div>
 
         <!-- Password Field -->
         <div class="mb-4">
            <Label for="password" value="Password" class="block mb-2 text-sm font-medium text-gray-700" />
            <Input 
               type="password" 
               bind:value={password} 
               placeholder="Enter your password" 
               class="w-full" 
               on:keydown={handleKeyDown} 
               required 
            />
         </div>
 
         <!-- Confirm Password Field -->
         <div class="mb-6">
            <Label for="confirmPassword" value="Confirm Password" class="block mb-2 text-sm font-medium text-gray-700" />
            <Input 
               type="password" 
               bind:value={confirmPassword} 
               placeholder="Re-enter your password" 
               class="w-full" 
               on:keydown={handleKeyDown} 
               required 
            />
         </div>
 
         <!-- Submit Button -->
          {#if  loading}
            <div class="flex justify-center">
                  <Spinner></Spinner>
            </div>
            {:else}
         <Button 
            type="submit" 
            color="green" 
            class="w-full mb-4" 
         >
            Sign Up
         </Button>
         {/if}

      </form>
 
      <!-- Additional Links -->
      <div class="text-center">
         <span class="text-sm text-gray-600">Already have an account? </span>
         <a href="/login" class="text-green-500 hover:underline text-sm">
            Login
         </a>
      </div>
   </div>
 </div>
 
