import { fetch_api } from "../../service/http_calls"


export let list_chat_conversation_message = async () => {
    return await fetch_api('/chat_conversation_message', 'GET');
}

export let create_chat_conversation_message = async (body) => {
    return await fetch_api('/chat_conversation_message', 'POST', {}, body);
}

export let clear_chat_conversation_message = async () => {
    return await fetch_api('/chat_conversation_message', 'DELETE');
}