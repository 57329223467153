<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Radio,
        Datepicker,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { notifyStore } from "../service/notify";

    let datepickerStart = null;
    let datepickerEnd = null;

    let datepickerPrevStartDate = null;
    let datepickerPrevSubmissionDate = null;

    // Format date as yyyy-MM-dd
    function formatDate(date) {
        try {
            if (!date) return "yyyy-MM-dd";
            // Handle ISO string or Date object
            const dateObj = typeof date === "string" ? new Date(date) : date;
            if (!(dateObj instanceof Date) || isNaN(dateObj)) {
                console.warn("Invalid date provided to formatDate:", date);
                return "yyyy-MM-dd";
            }
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
            const day = dateObj.getDate().toString().padStart(2, "0");
            return `${year}-${month}-${day}`;
        } catch (error) {
            console.error("Error formatting date:", error);
            return "yyyy-MM-dd";
        }
    }

    // Watch for start date changes
    $: if ($notifyStore.startDate) {
        // Update notification period dates
        notifyStore.update((state) => ({
            ...state,
            notificationPeriodStartDate: formatDate(
                $notifyStore.startDate instanceof Date
                    ? $notifyStore.startDate
                    : new Date($notifyStore.startDate),
            ),
        }));
    }

    // Watch for end date changes
    $: if ($notifyStore.endDate) {
        const notificationEndTemp =
            $notifyStore.endDate instanceof Date
                ? new Date($notifyStore.endDate)
                : new Date($notifyStore.endDate);
        notificationEndTemp.setMonth(notificationEndTemp.getMonth() + 6);

        notifyStore.update((state) => ({
            ...state,
            notificationPeriodEndDate: formatDate(notificationEndTemp),
        }));

        // Calculate 3 years before notification end
        const threeyrsBeforeNotificationEnd = new Date(notificationEndTemp);
        threeyrsBeforeNotificationEnd.setFullYear(
            threeyrsBeforeNotificationEnd.getFullYear() - 3,
        );
        notifyStore.update((state) => ({
            ...state,
            threeyrsBeforeNotificationEnd: formatDate(
                threeyrsBeforeNotificationEnd,
            ),
        }));
    }

    // Validate dates
    $: {
        if (datepickerStart && datepickerEnd) {
            $notifyStore.endDateError =
                datepickerStart > datepickerEnd
                    ? "End date is earlier than start date; please check."
                    : "";
        }
        if (datepickerStart && datepickerPrevStartDate) {
            $notifyStore.prevStartDateError =
                datepickerPrevStartDate > datepickerStart
                    ? "Start date for the previous claim is later than that for the current claim; please check."
                    : "";
        }
        if (datepickerPrevStartDate && datepickerPrevSubmissionDate) {
            $notifyStore.submissionDateError =
                datepickerPrevSubmissionDate < datepickerPrevStartDate
                    ? "Submission date is earlier than accounting period start date; please check."
                    : "";
        }
    }
    $: {
        if (datepickerStart) {
            notifyStore.update((state) => ({
                ...state,
                startDate: formatDate(datepickerStart),
            }));
        }
    }

    $: {
        if (datepickerEnd) {
            notifyStore.update((state) => ({
                ...state,
                endDate: formatDate(datepickerEnd),
            }));
        }
    }

    $: {
        if (datepickerPrevStartDate) {
            notifyStore.update((state) => ({
                ...state,
                previousClaimStartDate: formatDate(datepickerPrevStartDate),
            }));
        }
    }

    $: {
        if (datepickerPrevSubmissionDate) {
            notifyStore.update((state) => ({
                ...state,
                previousClaimSubmissionDate: formatDate(
                    datepickerPrevSubmissionDate,
                ),
            }));
        }
    }

    function handleSubmit() {
        notifyStore.update((state) => ({
            ...state,
            showOutput: true,
        }));
        // Add logic here to determine if prenotification is needed based on decision tree
        notifyStore.update((state) => ({
            ...state,
            needsPrenotification: true,
        }));

        // Check if start date is before April 1st 2023
        const preNotificationCutoffDate = new Date("2023-04-01");
        const startDate =
            $notifyStore.startDate instanceof Date
                ? $notifyStore.startDate
                : new Date($notifyStore.startDate);
        if (startDate < preNotificationCutoffDate) {
            notifyStore.update((state) => ({
                ...state,
                needsPrenotification: false,
            }));
        } else {
            if ($notifyStore.isFirstClaim === "yes") {
                notifyStore.update((state) => ({
                    ...state,
                    needsPrenotification: true,
                }));
            } else {
                notifyStore.update((state) => ({
                    ...state,
                    needsPrenotification: false,
                }));
            }
        }
    }

    function clearAll() {
        notifyStore.update((state) => ({
            ...state,
            startDate: null,
            endDate: null,
            isFirstClaim: "yes",
            previousClaimType: "",
            wasRejected: "",
            previousClaimStartDate: null,
            previousClaimSubmissionDate: null,
            showOutput: false,
            endDateError: "",
            prevStartDateError: "",
            submissionDateError: "",
        }));
    }
    $: submitDisabled =
        !datepickerStart ||
        !datepickerEnd ||
        ($notifyStore.isFirstClaim === "no" &&
            (!$notifyStore.previousClaimType ||
                !$notifyStore.wasRejected ||
                !datepickerPrevStartDate ||
                !datepickerPrevSubmissionDate)) ||
        $notifyStore.endDateError ||
        $notifyStore.prevStartDateError ||
        $notifyStore.submissionDateError;

    onMount(() => {
        datepickerStart = $notifyStore.startDate
            ? new Date($notifyStore.startDate)
            : new Date();
        console.log($notifyStore.startDate);
        datepickerEnd = $notifyStore.endDate
            ? new Date($notifyStore.endDate)
            : new Date();
        datepickerPrevStartDate = $notifyStore.previousClaimStartDate
            ? new Date($notifyStore.previousClaimStartDate)
            : new Date();
        datepickerPrevSubmissionDate = $notifyStore.previousClaimSubmissionDate
            ? new Date($notifyStore.previousClaimSubmissionDate)
            : new Date();
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="w-full mb-40">
            <Accordion multiple collapse={true}     activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
            inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
        >
                <AccordionItem open>
                    <span slot="header"
                        >Start date for the period of account</span
                    >
                    <div class="md:w-1/2">
                        <Datepicker
                            inline
                            bind:value={datepickerStart}
                            class="w-full"
                            range={false}
                            on:change={({ detail }) => {
                                console.log(detail);
                                notifyStore.update((state) => ({
                                    ...state,
                                    startDate: detail
                                        ? formatDate(detail)
                                        : null,
                                }));
                            }}
                        />
                        <P class="mt-4"
                            >Selected date: {datepickerStart
                                ? formatDate(datepickerStart)
                                : "None"}</P
                        >
                    </div>
                </AccordionItem>

                <AccordionItem open>
                    <span slot="header"
                        >End date for the period of account</span
                    >
                    <div class="space-y-4">
                        <div class="md:w-1/2">
                            <Datepicker
                                inline
                                bind:value={datepickerEnd}
                                class="w-full"
                                range={false}
                                on:change={({ detail }) => {
                                    notifyStore.update((state) => ({
                                        ...state,
                                        endDate: detail
                                            ? formatDate(detail)
                                            : null,
                                    }));
                                }}
                            />
                            <P class="mt-4"
                                >Selected date: {datepickerEnd
                                    ? formatDate(datepickerEnd)
                                    : "None"}</P
                            >
                            {#if $notifyStore.endDateError}
                                <P class="text-red-500 text-sm mt-2"
                                    >{$notifyStore.endDateError}</P
                                >
                            {/if}
                        </div>
                    </div>
                </AccordionItem>

                <AccordionItem open>
                    <span slot="header"
                        >Is this the company's first R&D claim?</span
                    >
                    <div class="space-y-4">
                        <Radio
                            bind:group={$notifyStore.isFirstClaim}
                            value="yes">Yes</Radio
                        >
                        <Radio 
                            bind:group={$notifyStore.isFirstClaim} 
                            value="no">No</Radio
                        >
                    </div>
                </AccordionItem>
                {#if $notifyStore.isFirstClaim === "no"}
                    <AccordionItem open>
                        <span slot="header"
                            >Was the previous claim part of the
                            original return, or by amendment?</span
                        >
                        <div class="space-y-4">
                            <Radio
                                bind:group={$notifyStore.previousClaimType}
                                value="original">Original</Radio
                            >
                            <Radio
                                bind:group={$notifyStore.previousClaimType}
                                value="amendment">By Amendment</Radio
                            >
                        </div>
                    </AccordionItem>

                    <AccordionItem open>
                        <span slot="header"
                            >Was the previous claim rejected by
                            HMRC?</span
                        >
                        <div class="space-y-4">
                            <Radio 
                                bind:group={$notifyStore.wasRejected} 
                                value="yes">Yes</Radio
                            >
                            <Radio 
                                bind:group={$notifyStore.wasRejected} 
                                value="no">No</Radio
                            >
                        </div>
                    </AccordionItem>

                    <AccordionItem open>
                        <span slot="header"
                            >Accounting period start date for the
                            previous claim</span
                        >
                        <div class="md:w-1/2">
                            <Datepicker
                                inline
                                bind:value={datepickerPrevStartDate}
                                class="w-full"
                                range={false}
                            />
                            <P class="mt-4"
                                >Selected date: {datepickerPrevStartDate
                                    ? formatDate(datepickerPrevStartDate)
                                    : "None"}</P
                            >
                            {#if $notifyStore.prevStartDateError}
                                <P class="text-red-500 text-sm mt-2"
                                    >{$notifyStore.prevStartDateError}</P
                                >
                            {/if}
                        </div>
                    </AccordionItem>

                    <AccordionItem open>
                        <span slot="header"
                            >Submission date for the previous claim</span
                        >
                        <div class="md:w-1/2">
                            <Datepicker
                                inline
                                bind:value={datepickerPrevSubmissionDate}
                                class="w-full"
                                range={false}
                            />
                            <P class="mt-4"
                                >Selected date: {datepickerPrevSubmissionDate
                                    ? formatDate(datepickerPrevSubmissionDate)
                                    : "None"}</P
                            >
                            {#if $notifyStore.submissionDateError}
                                <P class="text-red-500 text-sm mt-2"
                                    >{$notifyStore.submissionDateError}</P
                                >
                            {/if}
                        </div>
                    </AccordionItem>
                {/if}
            </Accordion>

            <div class="flex justify-end gap-4 mt-6">
                <Button color="red" on:click={clearAll}>Clear All</Button>
                <Button
                    color="purple"
                    on:click={handleSubmit}
                    disabled={submitDisabled}>Submit</Button
                >
            </div>

            {#if $notifyStore.showOutput}
                <div
                    class="mt-6 p-4 rounded-lg"
                    class:bg-green-50={!$notifyStore.needsPrenotification}
                    class:bg-red-50={$notifyStore.needsPrenotification}
                >
                    {#if $notifyStore.needsPrenotification}
                        <P class="font-semibold"
                            >YES, prenotification is needed. The notification
                            period is:</P
                        >
                        <P
                            >Start date: {formatDate(
                                $notifyStore.notificationPeriodStartDate,
                            )}</P
                        >
                        <P
                            >End date: {formatDate(
                                $notifyStore.notificationPeriodEndDate,
                            )}</P
                        >
                    {:else}
                        <P class="font-semibold"
                            >No, prenotification is NOT needed.</P
                        >
                    {/if}
                </div>
                <div class="mt-4">
                    <P
                        >HMRC guideline on claim notification <a
                            href="https://www.gov.uk/guidance/tell-hmrc-that-youre-planning-to-claim-research-and-development-rd-tax-relief"
                            class="underline text-blue-600"
                            target="_blank">here</a
                        ></P
                    >
                </div>
            {/if}
        </Card>
    </div>
</div>
