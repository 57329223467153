<script>
    import { AccordionItem, Button, Spinner } from "flowbite-svelte";
    import { marked } from "marked";
    import {
        current_user,
        review,
        review_instructions_modal,
        selected_module,
        selected_section,
        selected_sub_section,
    } from "../service/store.js";
    import {
        BadgeCheckSolid,
        ExclamationCircleSolid,
        FileCopyOutline,
    } from "flowbite-svelte-icons";
    import { toast } from "@zerodevx/svelte-toast";
    import { async_ask_llm } from "../service/http_calls.js";

    export let section;
    export let sub_section;
    export let overriden_title = undefined;


    async function handleRegenerate() {
        review.update((prev) => {
            prev[section][sub_section]["loading"] = true;
            return prev;
        });

        // let instructions =
        //     $review.user_instructions.baseline +
        //     "\n" +
        //     $review.user_instructions.advance +
        //     "\n" +
        //     $review.user_instructions.uncertainty +
        //     "\n" +
        //     $review.user_instructions.resolution +
        //     "\n" +
        //     ($review[section][sub_section]?.instructions ?? "");

       
        let instructions = "";
        if (section === "uncertainty"){
            instructions = $review.user_instructions.uncertainty;
        } else if (section === "advance"){
            instructions = $review.user_instructions.advance;
        } else if (section === "baseline"){
            instructions = $review.user_instructions.baseline;
        } else if (section === "resolution"){
            instructions = $review.user_instructions.resolution;
        }

        if (section=="overall" || section=="baseline_research"){
            instructions = $review.user_instructions.baseline + "\n" + $review.user_instructions.advance + "\n" + $review.user_instructions.uncertainty + "\n" + $review.user_instructions.resolution;
        }


        const resp_coherence = await async_ask_llm(
            instructions + "\n" + ($review[section][sub_section]?.instructions ?? ""),
            "review",
            section,
            sub_section,
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev[section][sub_section]["result_id"] = resp_coherence.result_id;
            return prev;
        });

    }
</script>

<AccordionItem>
    <span slot="header" class="inline-flex items-center mr-10">
        {#if overriden_title}
            {overriden_title}
        {:else}
            {sub_section
                .split("_")
                .map(
                    (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase(),
                )
                .join(" ")}
        {/if}

        {#if $review?.[section]?.[sub_section]?.response
            ?.toLowerCase()
            .includes("no improvement needed") || $review?.[section]?.[sub_section]?.response
                ?.toLowerCase()
                .includes("no improvement required")}
            <BadgeCheckSolid class="w-5 h-5 ml-2 text-green-500"
            ></BadgeCheckSolid>
        {:else if $review?.[section]?.[sub_section]?.response
            ?.toLowerCase()
            .includes("optional improvement")}
            <BadgeCheckSolid class="w-5 h-5 ml-2 text-yellow-500"
            ></BadgeCheckSolid>
        {:else if $review?.[section]?.[sub_section]?.response
            ?.toLowerCase()
            .includes("improvement needed") || $review?.[section]?.[sub_section]?.response
                ?.toLowerCase()
                .includes("improvement required")}
            <ExclamationCircleSolid class="w-5 h-5 ml-2 text-red-500"
            ></ExclamationCircleSolid>
        {/if}
    </span>
    <div class="prose max-w-full">
        {#if $review?.[section]?.[sub_section]?.response}
            {@html marked($review?.[section]?.[sub_section]?.response)}
        {/if}
    </div>

    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    navigator.clipboard.writeText(
                        $review?.[section]?.[sub_section]?.response,
                    );
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
        </div>

        <div class="flex justify-end">
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    selected_module.set("review");
                    selected_section.set(section);
                    selected_sub_section.set(sub_section);
                    review_instructions_modal.set(true);
                }}>Add Instructions</Button
            >
            {#if $review?.[section]?.[sub_section]?.loading}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={async () => {
                        await handleRegenerate();
                    }}>Regenerate</Button
                >
            {/if}
        </div>
    </div>
</AccordionItem>
