<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Tabs,
        TabItem,
        Textarea,
        Modal,
        Dropdown,
        DropdownItem,
        Checkbox,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import {
        async_ask_llm,
        deleteAllFiles,
        download_file,
        fetchFiles,
    } from "../service/http_calls";
    import {
        defend,
        defend_instructions_modal_open,
        defend_submit_loading,
        files_list,
        initial_defend,
        selected_section,
        selected_sub_section,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import SingleDefendItem from "./SingleDefendItem.svelte";
    import { toast } from "@zerodevx/svelte-toast";
    import { ChevronDownOutline } from "flowbite-svelte-icons";

    let clearResponsesModal = false;
    let downloading_file = false;
    let activeTab = "standard";

    onMount(async () => {
        const resp = await fetchFiles("defend");

        files_list.update((prev) => {
            if (Array.isArray(resp)) {
                prev["defend"] = resp;
            }
            return prev;
        });
    });

    async function handleSubmit() {
        console.log("submit");
        defend_submit_loading.set(true);
        if (activeTab === "standard") {
            defend_submit_loading.set(true);
            let sections = [
                "q1_1",
                "q1_2",
                "q1_3",
                "q1_4",
                "q1_5",
                "q1_6",
                "q2_1",
                "q2_2",
                "q2_3",
                "q2_4",
                "q3_0",
                "q3_1",
                "q3_2",
                "q3_3",
                "q4_1",
                "q4_2",
                "q4_3",
                "q4_4",
                "q4_5",
                "q4_6",
                "q5",
            ];

            for (let section of sections) {
                console.log("section", section);
                let resp = await async_ask_llm(
                    $defend.user_instructions,
                    "defend",
                    section,
                    "outline",
                    "gpt-4o",
                    [],
                );
                defend.update((prev) => {
                    prev[section]["outline"]["result_id"] = resp.result_id;
                    return prev;
                });
            }
        } else if (activeTab === "freeform") {
            defend_submit_loading.set(true);
            let resp = await async_ask_llm(
                $defend.user_instructions +
                    " HMRC question: " +
                    $defend.freeform.question,
                "defend",
                "freeform",
                "outline",
                "gpt-4o",
                [],
            );
            defend.update((prev) => {
                prev["freeform"]["outline"]["result_id"] = resp.result_id;
                return prev;
            });
        }
    }

    async function handleDownload(type) {
        downloading_file = true;

        if (activeTab === "standard") {
            let payload = {};
            Object.keys($defend).forEach((key) => {
                if (key !== "user_instructions") {
                    Object.keys($defend[key]).forEach((sub_key) => {
                        payload[key + "_" + sub_key] =
                            $defend[key][sub_key].generated_content;
                    });
                }
            });
            let resp = await download_file(payload, type, "defend", "Defend");
        } else {
            let payload = {
                "freeform_outline": $defend.freeform.outline.generated_content,
                "freeform_draft": $defend.freeform.draft.generated_content,
            };
            let resp = await download_file(payload, type, "defend_freeform", "Defend");
        }

        downloading_file = false;
    }

    $: submit_disabled =
        $files_list["defend"].length == 0 ||
        $files_list["defend"].some((file) => file.status !== "Ready") ||
        (activeTab === "freeform" && $defend.freeform.question.trim() === "");


    let clear_instructions = false;
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="w-full mb-40">

            <ul
                class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
            >
                {#each ["standard", "freeform"] as tab}
                    <li class="me-2">
                        <a
                            href="#"
                            class="inline-block p-4 rounded-t-lg"
                            class:active={activeTab === tab}
                            class:text-blue-600={activeTab === tab}
                            class:bg-gray-100={activeTab === tab}
                            class:dark:bg-gray-800={activeTab === tab}
                            class:dark:text-blue-500={activeTab === tab}
                            on:click={() => (activeTab = tab)}
                        >
                            {tab === "standard"
                                ? "WMBC template"
                                : "Freeform questions"}
                        </a>
                    </li>
                {/each}
            </ul>

            <FilesTable module="defend"></FilesTable>

            {#if activeTab === "freeform"}
                <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
                    >Please add enquiry question here</P
                >
                <Textarea
                    rows="5"
                    class="mb-3"
                    bind:value={$defend.freeform.question}
                ></Textarea>
            {/if}

            <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
                >Add instructions (Optional):</P
            >

            <Textarea
                rows="5"
                class="mb-3"
                bind:value={$defend.user_instructions}
            ></Textarea>
            <div class="flex justify-end my-5">
                {#if $defend_submit_loading}
                    <div class="flex justify-center my-5">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button
                        size="sm"
                        color="purple"
                        class="hover:bg-purple-700 transition-colors duration-200"
                        on:click={handleSubmit}
                        disabled={submit_disabled}>Submit</Button
                    >
                {/if}
            </div>

            {#if activeTab === "standard"}
                <P size="lg" class="my-5 font-bold"
                    ><Span gradient>Advance</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please explain further the advance in science and technology that was sought in the project by answering the following questions:
</P
                >

                <ul
                    class="list-disc list-inside mb-5 space-y-2 text-gray-700 dark:text-gray-300"
                >
                    <li>
                    Detail the start and end dates for the R&D project that you have claimed for within the period.
                    </li>
                    <li>
                    Explain what the gap in technological knowledge or capability was, which necessitated the start of the R&D. This must be based on what was available and known in the field of science and technology, not the state of your company's knowledge or expertise on the subject.
                    </li>
                    <li>
                    Explain what must be accomplished for the project to be considered the end of the R&D.
                    </li>
                    <li>
                    Explain what scientific or technological advance is being sought or achieved during the development of the project. This must not be merely enhancing your ability or company's ability to achieve a commercial outcome, but rather an objective that seeks to advance the wider field of science and technology.
                    </li>
                    <li>
                    Explain how the R&D project is new in the field of science and technology, or appreciably improves on an existing field of technology relative to what is available in the public domain. Appreciable improvement should be evaluated against the baseline of technology available within the wider field of science and technology.
                    </li>
                    <li>
                    Explain why this was not readily deducible by a competent professional. Please provide the competent professional's statement for their reasoning regarding this assessment.
                    </li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >1. Detail the start and end dates for each Research & Development project that you have claimed for within the period.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_1"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_1"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold  mt-20 mb-5"
                    >2. Explain what the gap in technological knowledge or capability was, which necessitated the start of the R&D. This must be based on what was available and known in the field of science and technology, not the state of your company's knowledge or expertise on the subject.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_2"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_2"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >3. Explain what must be accomplished for the project to be considered the end of the R&D.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_3"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_3"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold  mt-20 mb-5"
                    >4. Explain what scientific or technological advance is being sought or achieved during the development of the project. This must not be merely enhancing your ability or company's ability to achieve a commercial outcome, but rather an objective that seeks to advance the wider field of science and technology.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_4"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_4"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >5. Explain how each R&D project is new in the field of science and technology, or appreciably improves on an existing field of technology relative to what is available in the public domain. Appreciable improvement should be evaluated against the baseline of technology available within the wider field of science and technology.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_5"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_5"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

           <P class="font-semibold   mt-20 mb-5"
                    >6. Explain why this was not readily deducible by a competent professional. Please provide the competent professional's statement for their reasoning regarding this assessment.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q1_6"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q1_6"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>



                <P size="lg" class="mt-20 mb-5 font-bold"
                    ><Span gradient>Baseline</Span></P
                >
                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please provide a valid baseline that was used for the project by answering the following questions:</P
                >

                <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>Provide a baseline in science or technology. This must not be a baseline of your own abilities or knowledge as a company but what was present in the wider field of science and technology.</li>
                    <li>
                    Provide us with the research conducted to determine this baseline.
                    </li>
                    <li>
   Provide the comparative analysis of the baseline technologies available in the wider field and the advance sought.
                    </li>
                    <li>
                        Provide any facts and figures that were used while making this analysis.
                    </li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >1. Provide a baseline in science or technology. This must not be a baseline of your own abilities or knowledge as a company but what was present in the wider field of science and technology.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q2_1"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q2_1"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >2. Provide us with the research conducted to determine this baseline.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q2_2"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q2_2"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >3. Provide the comparative analysis of the baseline technologies available in the wider field and the advance sought.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q2_3"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q2_3"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >4. Provide any facts and figures that were used while making this analysis.

</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q2_4"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q2_4"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>
                <div class="my-30"></div>

                <P size="lg" class="mt-20 mb-5 font-bold"
                    ><Span gradient>Uncertainty</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please explain what the scientific or technological uncertainties were by answering the following questions:</P
                >

                <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>Provide an explanation of the research conducted to establish these were uncertain in the field of technology. Please note just because your company does not know if something could be done, it does not make it an uncertainty in the wider field of science and technology.</li>
                    <li>What were the current technological limitations that didn’t allow you to resolve these uncertainties in each project? Technological limitation must be assessed with the knowledge and technology available in the wider field and not against what the company can and cannot accomplish.</li>
                    <li>Explain the current practices used in the wider field and why it fails to resolve the uncertainties faced.</li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >0. Please explain what the scientific or technological uncertainties were.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q3_0"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q3_0"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >1. Provide an explanation of the research conducted to establish these were uncertain in the field of technology. Please note just because your company does not know if something could be done, it does not make it an uncertainty in the wider field of science and technology.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q3_1"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q3_1"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >2. What were the current technological limitations that didn’t allow you to resolve these uncertainties in each project? Technological limitation must be assessed with the knowledge and technology available in the wider field and not against what the company can and cannot accomplish.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q3_2"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q3_2"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="mt-20 mb-5 text-gray-700 dark:text-gray-300 font-semibold"
                    >3. Explain the current practices used in the wider field and why it fails to resolve the uncertainties faced.</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q3_3"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q3_3"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P size="lg" class="  mt-20 mb-5 font-bold"
                    ><Span gradient>Resolutiion</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please detail how and when the uncertainties were overcome by answering the following questions. Please consider paragraph 14 of BEIS guidelines when answering these questions:</P
                >

            <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>Explain if the methods employed to solve the uncertainties were not already known and established in the public domain.</li>
                    <li>Explain how resolving the uncertainties mentioned was not business as usual in the field of computer science and application development?</li>
                    <li>Provide a detailed timeline. This must include all stages from planning to deployment of the R&D outcome.</li>
                    <li>Provide details of what individuals were involved in at each stage and any related documents you hold that were produced throughout each stage.

</li>
                    <li>Explain how and when the uncertainties were identified.</li>
                    <li>Explain how and when the uncertainties were overcome at all stages from planning to deployment.</li>
                </ul>
                <P class="font-semibold   mt-20 mb-5">1. Explain if the methods employed to solve the uncertainties were not already known and established in the public domain.</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_1"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_1"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5">2. Explain how resolving the uncertainties mentioned was not business as usual in the field of computer science and application development?</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_2"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_2"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

             <P class="font-semibold   mt-20 mb-5">3. Provide a detailed timeline. This must include all stages from planning to deployment of the R&D outcome.</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_3"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_3"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

             <P class="font-semibold   mt-20 mb-5">4. Provide details of what individuals were involved in at each stage and any related documents you hold that were produced throughout each stage.</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_4"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_4"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

             <P class="font-semibold   mt-20 mb-5">5. Explain how and when the uncertainties were identified.</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_5"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_5"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

             <P class="font-semibold   mt-20 mb-5">6. Explain how and when the uncertainties were overcome at all stages from planning to deployment.</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q4_6"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q4_6"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P size="lg" class="  mt-20 mb-5 font-bold"
                    ><Span gradient>Competent Professionals</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please provide further details about the competent professional mentioned in your report by answering the following questions:</P
                >

            <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>What specific experience does the competent professional have in the field of application and web development?</li>
                    <li>Please provide information on the projects and activities that the competent professional has done in the past.</li>
                    <li>Please provide any record of accomplishment in similar projects.</li>
                    <li>Please provide information of the specific experience the competent professional has in this field or area?

</li>
                </ul>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="q5"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="q5"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>


            {/if}
            {#if activeTab === "freeform"}
                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="freeform"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="freeform"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>
            {/if}

            <div class="flex justify-end mt-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >
                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = "";
                                Object.keys($defend).forEach((key) => {
                                    if (key !== "user_instructions") {
                                        Object.keys($defend[key]).forEach(
                                            (sub_key) => {
                                                text +=
                                                    $defend[key][sub_key]
                                                        .generated_content +
                                                    "\n\n";
                                            },
                                        );
                                    }
                                });
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>

<Modal
    title="Clear All"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all files and outputs?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone.
    </p>
    <svelte:fragment slot="footer">
        <Checkbox bind:checked={clear_instructions}>Clear All Instructions</Checkbox>
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                defend.update((prev) => initial_defend);
                await deleteAllFiles("defend");
                files_list.update((prev) => {
                    prev["defend"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>

<Modal
    title="Add Instructions"
    bind:open={$defend_instructions_modal_open}
    autoclose
>
    <Textarea
        rows="5"
        bind:value={$defend[$selected_section][$selected_sub_section]
            .user_instructions}
    ></Textarea>
    <div class="flex justify-end">
        <Button
            on:click={() => {
                defend_instructions_modal_open.set(false);
            }}>Save</Button
        >
    </div>
</Modal>
