<script>
   import { toast } from "@zerodevx/svelte-toast";
   import {
      change_password,
      change_password_request,
      login,
   } from "../service/http_calls";
   import OTP from "../lib/OTP.svelte";
   // Importing necessary components from svelte-flowbite
   import { Button, Label, Input, A, Spinner } from "flowbite-svelte";
   import {
      email_address,
      show_new_password,
      show_otp,
      temporary_token,
   } from "../service/store";

   let password = "";
   let confirmPassword = "";
   let loading = false;
   let loading_forgot_password = false;
   let loading_new_password = false;

   async function handleLogin(event) {
      event.preventDefault();

      // Prepare form data
      const formData = new FormData();
      formData.append("username", $email_address);
      formData.append("password", password);

      try {
         loading = true;
         const response = await login(formData);
         loading = false;
         console.log(response);
         if (response.id != undefined) {
            toast.push("Login successful", { type: "success" });
            window.location.href = "/drafting";
            // Redirect or perform additional actions here
         } else {
            toast.push("Login failed, please check your credentials.", {
               type: "error",
            });
            if (response.detail.code == 112){
               await handleForgot();
            }
         }
      } catch (error) {
         toast.push("An error occurred. Please try again later.", {
            type: "error",
         });
         console.error(error);
      }
   }

   // Function to detect 'Enter' key press
   function handleKeyDown(event) {
      if (event.key === "Enter") {
         handleLogin(event);
      }
   }

   async function handleForgot() {
      if ($email_address.trim() === "") {
         toast.push("Please enter your email address", { classes: ["warn"] });
      } else {
         show_otp.set(true);
         loading_forgot_password = true;

         let resp = await change_password_request($email_address);
         toast.push(resp.detail.message);
         // window.location.href = "/verify";
      }
      loading_forgot_password = false;
   }

   async function handleNewPassword() {
      if (password != confirmPassword) {
         toast.push("Incorrect password, please check");
      } else {
         loading_new_password = true;
         let resp = await change_password(
            $email_address,
            password,
            $temporary_token,
         );
         loading_new_password = false;
         toast.push(resp.detail.message);
      }
   }
</script>

<!-- Container for the login form -->
<div class="flex justify-center items-center min-h-screen bg-gray-100">
   <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h2 class="text-2xl font-semibold text-center mb-6">Login</h2>

      <!-- Login Form -->
      <form on:submit|preventDefault={handleLogin}>
         <!-- Username Field -->
         <div class="mb-4">
            <Label
               for="username"
               value="Email"
               class="block mb-2 text-sm font-medium text-gray-700"
            />
            <Input
               id="username"
               type="text"
               bind:value={$email_address}
               placeholder="Enter your email"
               class="w-full"
               on:keydown={handleKeyDown}
               required
            />
         </div>

         <!-- Password Field -->
         <div class="mb-6">
            <Label
               for="password"
               value="Password"
               class="block mb-2 text-sm font-medium text-gray-700"
            />
            <Input
               id="password"
               type="password"
               bind:value={password}
               placeholder="Enter your password"
               class="w-full"
               on:keydown={handleKeyDown}
               required
            />
         </div>

         <!-- Submit Button -->
         {#if loading}
            <div class="flex justify-center">
               <Spinner></Spinner>
            </div>
         {:else}
            <Button type="submit" color="blue" class="w-full mb-4">
               Login
            </Button>
         {/if}
      </form>

      <!-- Additional Links -->
      <div class="flex justify-between">
         {#if loading_forgot_password}
            <Spinner size={5}></Spinner>
         {:else}
            <a
               class="text-blue-500 hover:underline text-sm"
               on:click={async () => {
                  await handleForgot();
               }}
            >
               Forgot Password?
            </a>
         {/if}

         <a href="/early-access" class="text-blue-500 hover:underline text-sm">
            Sign Up
         </a>
      </div>

      {#if $show_otp}
         <div class="flex justify-center mt-10">
            <div class="flex flex-col">
               <OTP></OTP>
            </div>
         </div>
      {/if}

      {#if $show_new_password}
         <!-- Password Field -->
         <div class="mb-4">
            <Label
               for="password"
               value="Password"
               class="block mb-2 text-sm font-medium text-gray-700"
            />
            <Input
               type="password"
               bind:value={password}
               placeholder="Enter your password"
               class="w-full"
               required
            />
         </div>

         <!-- Confirm Password Field -->
         <div class="mb-6">
            <Label
               for="confirmPassword"
               value="Confirm Password"
               class="block mb-2 text-sm font-medium text-gray-700"
            />
            <Input
               type="password"
               bind:value={confirmPassword}
               placeholder="Re-enter your password"
               class="w-full"
               required
            />
         </div>
         {#if loading_new_password}
         <div class="flex justify-center">
            <Spinner></Spinner>
         </div>
         {:else}            
         <Button on:click={async () => {
            await handleNewPassword();
         }}>Change Password</Button>
         {/if}

      {/if}
   </div>
</div>
