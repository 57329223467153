<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { AccordionItem, Button, Spinner, P } from "flowbite-svelte";
    import { FileCopyOutline, TrashBinOutline } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import {
        current_user,
        generated_content,
        research_add_instructions_modal_open,
        selected_model,
        selected_research_section,
        selected_research_sub_section,
    } from "../service/store";
    import { ask_llm } from "../service/http_calls";

    export let title = "";
    export let formattedContent = "";
    export let module = "";
    export let section = "";
    export let sub_section = "";
    export let injected_extra_prompt = "";
    export let submit_disabled = false;

    let loading = false;

    const handleRegenerate = async () => {
        loading = true;

        if ($current_user?.user_type !== "admin") {
            selected_model.set("gemini-1.5-pro");
            if (
                module == "research" &&
                section == "ask" &&
                sub_section == "internet"
            ) {
                selected_model.set("perplexity");
            }
            if (
                module == "research" &&
                section == "ask" &&
                sub_section == "general"
            ) {
                selected_model.set("gpt-4o");
            }
            if (
                module == "research" &&
                section == "upload" &&
                sub_section == "academic_research"
            ) {
                selected_model.set("gemini-2.0-flash-exp");
            }
            if (
                module == "research" &&
                section == "ask" &&
                sub_section == "academic_research"
            ) {
                selected_model.set("gemini-2.0-flash-exp");
            }
        }
        let use_files = true;
        if (
            module == "research" &&
            section == "upload" &&
            sub_section == "internet"
        ) {
            use_files = false;
        }
        if (module == "research" && sub_section == "academic_research") {
            use_files = false;
        }
        let resp = await ask_llm(
            injected_extra_prompt +
                $generated_content[module][section][sub_section][
                    "user_instructions"
                ],
            module,
            section,
            sub_section,
            $selected_model,
            use_files,
        );
        generated_content.update((prev) => {
            prev[module][section][sub_section]["generated_content"] =
                resp.generated_content;
            return prev;
        });
        loading = false;
        if (
            module == "research" &&
            section == "upload" &&
            sub_section == "baseline"
        ) {
            toast.push("Baseline statements done");
        } else if (
            module == "research" &&
            section == "upload" &&
            sub_section == "internet"
        ) {
            toast.push("Internet research done");
        }
    };
</script>

<AccordionItem>
    <span slot="header">{title}</span>
    <div class="prose max-w-full">
        <div>
            {#if formattedContent}
                {@html marked(formattedContent)}
            {:else}
                <small>No content</small>
            {/if}
        </div>
    </div>
    {#if title == "Internet Search"}
        <div class="flex justify-center my-10">
            <P size="xs" class="text-gray-800"
                >AI searches only the current Internet and cannot retrieve past
                or historical data.</P
            >
        </div>
    {/if}

    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    if (navigator && navigator.clipboard && formattedContent) {
                        navigator.clipboard.writeText(formattedContent);
                    } else {
                        console.error("Clipboard API not available");
                    }
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
            <button
                on:click={() => {
                    generated_content.update((prev) => {
                        prev[module][section][sub_section][
                            "generated_content"
                        ] = "";
                        return prev;
                    });
                }}
            >
                <TrashBinOutline></TrashBinOutline>
            </button>
        </div>

        <div class="flex justify-end">
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    if (module == "research") {
                        console.log("setting section and sub section", section, sub_section);
                        selected_research_section.set(section);
                        selected_research_sub_section.set(sub_section);
                        research_add_instructions_modal_open.set(true);
                    }
                }}>Add Instructions</Button
            >
            {#if loading}
                <Spinner></Spinner>
            {:else if submit_disabled}
                <Button size="xs" color="purple" disabled>Regenerate</Button>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={() => {
                        handleRegenerate();
                    }}>Regenerate</Button
                >
            {/if}
        </div>
    </div>
</AccordionItem>
