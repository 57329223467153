<script>
    import { Button, Dropdown, DropdownItem, P } from "flowbite-svelte";
    import FilesTable from "./FilesTable.svelte";
    import { Spinner } from "flowbite-svelte";
    import {
        loading_project_description,
        project_description_general_prompt_edit,
        files_list,
        project_description_general_prompt,
    } from "../service/store";

    import { Accordion, AccordionItem } from "flowbite-svelte";
    import { marked } from "marked";
    import { toast } from "@zerodevx/svelte-toast";
    import {
        ChevronDownOutline,
        FileCopyOutline,
        TrashBinOutline,
    } from "flowbite-svelte-icons";
    import {
        clear_all_modal_open_notify,
        notifyStore,
    } from "../service/notify";
    import {
        async_ask_llm,
        download_file,
        fetchFiles,
    } from "../service/http_calls";
    import { onMount } from "svelte";

    let formattedContent = "";
    let submit_disabled = false;
    let clearResponsesModal = false;

    async function handle_option_1() {
        notifyStore.update((prev) => {
            prev["project_description"]["option_1"]["loading"] = true;
            return prev;
        });

        let resp_option_1 = await async_ask_llm(
            $notifyStore["project_description"]["option_1"][
                "user_instructions"
            ] +
                $project_description_general_prompt +
                $notifyStore["project_description"]["option_1"][
                    "user_instructions"
                ],
            "notify",
            "project_description",
            "option_1",
            "gpt-4o",
        );
        notifyStore.update((prev) => {
            prev["project_description"]["option_1"]["result_id"] =
                resp_option_1.result_id;
            return prev;
        });
    }
    async function handle_option_2() {
        notifyStore.update((prev) => {
            prev["project_description"]["option_2"]["loading"] = true;
            return prev;
        });

        let resp_option_2 = await async_ask_llm(
            $notifyStore["project_description"]["option_2"][
                "user_instructions"
            ] +
                $project_description_general_prompt +
                $notifyStore["project_description"]["option_2"][
                    "user_instructions"
                ],
            "notify",
            "project_description",
            "option_2",
            "gpt-4o",
        );
        notifyStore.update((prev) => {
            prev["project_description"]["option_2"]["result_id"] =
                resp_option_2.result_id;
            return prev;
        });
    }

    async function handleSubmit() {
        loading_project_description.set(true);
        await handle_option_1();
        await handle_option_2();
    }

    function handleTrash() {
        console.log("Trash");
    }

    $: submit_disabled =
        $files_list.notify.length == 0 ||
        $files_list.notify.some((file) => file.status !== "Ready");

    onMount(async () => {
        const resp = await fetchFiles("notify");

        files_list.update((prev) => {
            if (Array.isArray(resp)) {
                prev["notify"] = resp;
            }
            return prev;
        });
    });

    let downloading_file = false;
    async function handleDownload(doc_type) {
        downloading_file = true;
        let payload = {};
        Object.entries($notifyStore["project_description"]).forEach(
            ([key, value]) => {
                payload[key] = value.response?.content;
            },
        );

        let title = $notifyStore["project_description"]["option_1"]["content"];
        await download_file(payload, doc_type, "project_description", title);
        downloading_file = false;
    }
</script>

<FilesTable module="notify"></FilesTable>
<div class="mb-10">
    <div class="mt-5 flex justify-end">
        <Button
            size="sm"
            color="alternative"
            class="mr-5"
            on:click={() => {
                project_description_general_prompt_edit.set(true);
            }}>Add Instructions</Button
        >
        {#if $loading_project_description}
            <Spinner></Spinner>
        {:else}
            <Button
                color="purple"
                size="sm"
                disabled={$files_list.notify.length == 0 ||
                    $files_list.notify.some((file) => file.status !== "Ready")}
                on:click={() => {
                    handleSubmit();
                }}>Submit</Button
            >
        {/if}
    </div>
</div>
<div class="mt-5">
    <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
        Project Description
    </h3>
    <Accordion multiple     activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
>
        <AccordionItem open>
            <span slot="header"
                >Option 1: Structured</span
            >
            <div class="prose max-w-full">
                <div>
                    {#if $notifyStore["project_description"]["option_1"]["content"]}
                        {@html marked(
                            $notifyStore["project_description"]["option_1"][
                                "content"
                            ],
                        )}
                    {:else}
                        <small>No content</small>
                    {/if}
                </div>
            </div>

            <div class="flex justify-between mt-5">
                <div class="flex justify-start">
                    <button
                        on:click={() => {
                            if (
                                navigator &&
                                navigator.clipboard &&
                                formattedContent
                            ) {
                                navigator.clipboard.writeText(formattedContent);
                            } else {
                                console.error("Clipboard API not available");
                            }
                            toast.push("Copied to clipboard!");
                        }}
                        style="background: none; border: none; cursor: pointer;"
                        class="mr-5"
                    >
                        <FileCopyOutline></FileCopyOutline>
                    </button>
                    <button
                        on:click={() => {
                            handleTrash();
                        }}
                    >
                        <TrashBinOutline></TrashBinOutline>
                    </button>
                </div>

                <div class="flex justify-end">
                    <Button
                        size="xs"
                        color="alternative"
                        class="mr-5"
                        on:click={() => {
                            notifyStore.update((prev) => {
                                prev["project_description"]["option_1"][
                                    "add_instructions_modal_open"
                                ] = true;
                                return prev;
                            });
                        }}>Add Instructions</Button
                    >
                    {#if $notifyStore["project_description"]["option_1"]["loading"]}
                        <Spinner></Spinner>
                    {:else if !submit_disabled}
                        <Button
                            size="xs"
                            color="purple"
                            on:click={() => {
                                handle_option_1();
                            }}>Regenerate</Button
                        >
                    {/if}
                </div>
            </div>
        </AccordionItem>

        <AccordionItem open>
            <span slot="header">Option 2: Freeform</span>
            <div class="prose max-w-full">
                <div>
                    {@html marked(
                        $notifyStore["project_description"]["option_2"][
                            "content"
                        ],
                    )}
                </div>
            </div>

            <div class="flex justify-between mt-5">
                <div class="flex justify-start">
                    <button
                        on:click={() => {
                            if (
                                navigator &&
                                navigator.clipboard &&
                                formattedContent
                            ) {
                                navigator.clipboard.writeText(formattedContent);
                            } else {
                                console.error("Clipboard API not available");
                            }
                            toast.push("Copied to clipboard!");
                        }}
                        style="background: none; border: none; cursor: pointer;"
                        class="mr-5"
                    >
                        <FileCopyOutline></FileCopyOutline>
                    </button>
                    <button
                        on:click={() => {
                            handleTrash();
                        }}
                    >
                        <TrashBinOutline></TrashBinOutline>
                    </button>
                </div>

                <div class="flex justify-end">
                    <Button
                        size="xs"
                        color="alternative"
                        class="mr-5"
                        on:click={() => {
                            notifyStore.update((prev) => {
                                prev["project_description"]["option_2"][
                                    "add_instructions_modal_open"
                                ] = true;
                                return prev;
                            });
                        }}>Add Instructions</Button
                    >
                    {#if $notifyStore["project_description"]["option_2"]["loading"]}
                        <Spinner></Spinner>
                    {:else if !submit_disabled}
                        <Button
                            size="xs"
                            color="purple"
                            on:click={() => {
                                handle_option_2();
                            }}>Regenerate</Button
                        >
                    {/if}
                </div>
            </div>
        </AccordionItem>
    </Accordion>
</div>
<div class="flex justify-end mt-10">
    <Button
        color="red"
        class="mr-5"
        on:click={() => {
            clear_all_modal_open_notify.set(true);
        }}>Clear All</Button
    >
    {#if downloading_file}
        <div class="flex justify-center">
            <Spinner></Spinner>
        </div>
    {:else}
        <Button color="purple"
            >Download<ChevronDownOutline
                class="w-6 h-6 ms-2 text-white dark:text-white"
            /></Button
        >
        <Dropdown>
            <DropdownItem
                on:click={async () => {
                    await handleDownload("docx");
                }}>Docx</DropdownItem
            >
            <DropdownItem
                on:click={async () => {
                    await handleDownload("pdf");
                }}>PDF</DropdownItem
            >
            <DropdownItem
                on:click={() => {
                    let text = "";
                    Object.keys($notifyStore["project_description"]).forEach(
                        (key) => {
                            if (key !== "user_instructions") {
                                text +=
                                    $notifyStore["project_description"][key][
                                        "content"
                                    ] + "\n\n";
                            }
                        },
                    );
                    toast.push("Copied to clipboard");
                    navigator.clipboard.writeText(text);
                }}>Clipboard</DropdownItem
            >
        </Dropdown>
    {/if}
</div>
<div class="flex justify-center my-10">
    <P size="xs" class="text-gray-800"
        >AI may make mistakes. Please review the output carefully.</P
    >
</div>
