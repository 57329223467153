<script>
    import {
        Button,
        Card,
        Checkbox,
        Modal,
        TabItem,
        Tabs,
        Textarea,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import {
    files_list,
        isDeadlineTabOpen,
        isDescriptionTabOpen,
        project_description_general_prompt,
        project_description_general_prompt_edit,
    } from "../service/store";
    import PreNotificationDeadline from "./PreNotificationDeadline.svelte";
    import ProjectDescription from "./ProjectDescription.svelte";
    import { initialState, notifyStore } from "../service/notify";
    import { clear_all_modal_open_notify } from "../service/notify";
    import { deleteAllFiles } from "../service/http_calls";
    import { SvelteToast, toast } from "@zerodevx/svelte-toast";

    let clear_instructions = false;
    onMount(async () => {});
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <Tabs contentClass="bg-white">
                <TabItem
                    bind:open={$isDeadlineTabOpen}
                    title="Pre-notification Deadline"
                    on:click={() => {
                        isDeadlineTabOpen.set(true);
                        isDescriptionTabOpen.set(false);
                    }}
                >
                    <PreNotificationDeadline></PreNotificationDeadline>
                </TabItem>

                <TabItem
                    bind:open={$isDescriptionTabOpen}
                    title="Project Description"
                    on:click={() => {
                        isDeadlineTabOpen.set(false);
                        isDescriptionTabOpen.set(true);
                    }}
                >
                    <ProjectDescription></ProjectDescription>
                </TabItem>
            </Tabs>
        </Card>
    </div>
</div>

<Modal
    bind:open={$project_description_general_prompt_edit}
    size="lg"
    autoclose={false}
    class="w-full"
>
    <div class="text-start">
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Add Instructions
        </h3>
        <div class="mb-6">
            <Textarea
                rows={4}
                class="mx-auto"
                placeholder="Enter your instructions here..."
                bind:value={$project_description_general_prompt}
            />
        </div>
        <div class="flex justify-end gap-4">

            <Button
                color="purple"
                on:click={() => {
                    project_description_general_prompt_edit.set(false);
                }}
            >
                Save
            </Button>
        </div>
    </div>
</Modal>

<Modal
    bind:open={$notifyStore["project_description"]["option_1"][
        "add_instructions_modal_open"
    ]}
    size="lg"
    autoclose={false}
    class="w-full"
>
    <div class="text-center">
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Add Instructions
        </h3>
        <div class="mb-6">
            <Textarea
                rows={4}
                class="mx-auto"
                placeholder="Enter your instructions here..."
                bind:value={$notifyStore["project_description"]["option_1"][
                    "user_instructions"
                ]}
            />
        </div>
    </div>
    <div class="flex justify-end gap-4">

        <Button
            color="purple"
            on:click={() => {
                notifyStore.update((prev) => {
                    prev["project_description"]["option_1"][
                        "add_instructions_modal_open"
                    ] = false;
                    return prev;
                });
            }}
        >
            Save
        </Button>
    </div>
</Modal>

<Modal
    bind:open={$notifyStore["project_description"]["option_2"][
        "add_instructions_modal_open"
    ]}
    size="lg"
    autoclose={false}
    class="w-full"
>
    <div class="text-center">
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Add Instructions
        </h3>
        <div class="mb-6">
            <Textarea
                rows={4}
                class="mx-auto"
                placeholder="Enter your instructions here..."
                bind:value={$notifyStore["project_description"]["option_2"][
                    "user_instructions"
                ]}
            />
        </div>
    </div>
    <div class="flex justify-end gap-4">

        <Button
            color="purple"
            on:click={() => {
                notifyStore.update((prev) => {
                    prev["project_description"]["option_2"][
                        "add_instructions_modal_open"
                    ] = false;
                    return prev;
                });
            }}
        >
            Save
        </Button>
    </div>
</Modal>


<Modal title="Clear All" bind:open={$clear_all_modal_open_notify} autoclose>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all files and outputs?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone.
    </p>
    <svelte:fragment slot="footer">
        <Checkbox bind:checked={clear_instructions}
            >Clear All Instructions</Checkbox
        >
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                notifyStore.update((prev) => {
                    return initialState;
                });
                if (clear_instructions) {
                    project_description_general_prompt.set("");
                    Object.keys($notifyStore["project_description"]).forEach((key) => {
                        $notifyStore["project_description"][key].prompt = "";
                    });
                }
                await deleteAllFiles("notify");
                files_list.update((prev) => {
                    prev["notify"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear</Button
        >
        <Button
            color="alternative"
            on:click={() => (clear_all_modal_open_notify.set(false))}>Cancel</Button
        >
    </svelte:fragment>
</Modal>
