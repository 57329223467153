import { writable } from 'svelte/store';


export function createLocalStorageStore(key, initialValue) {
    let storedValue = localStorage.getItem(key);
    // console.log("Stored value for " + key + " is " + storedValue);

    if (storedValue === null || storedValue === undefined) {
        storedValue = initialValue;
    } else {
        try {
            storedValue = JSON.parse(storedValue);
        } catch (e) {
            console.error("Error parsing stored value for " + key, e);
            storedValue = initialValue;
        }
    }

    const store = writable(storedValue);

    store.subscribe(value => {
        localStorage.setItem(key, JSON.stringify(value));
    });

    return store;
}


export const terminal_messages = createLocalStorageStore("terminal_messages", []);
export const show_logs = createLocalStorageStore("show_logs", false);


export const sources = createLocalStorageStore("sources", "");
export const hallucination = createLocalStorageStore("hallucination", "");


export const email_address = writable("");

export const show_otp = writable(false);
export const show_new_password = writable(false);
export const temporary_token = writable("");

export const result_informed = writable({});


export const research_add_instructions_modal_open = writable(false);
export const research_add_general_instructions_modal_open = writable(false);
export const selected_research_section = writable("");
export const selected_research_sub_section = writable("");

export const research_general_instructions = createLocalStorageStore("research_general_instructions", "");



export const research_page_selected_tab = createLocalStorageStore("research_page_selected_tab", "upload");
export const selected_model = createLocalStorageStore("selected_model", "gemini-1.5-flash");



export const research_initial = {
    "research": {
        "upload": {
            "baseline": {
                "generated_content": "",
                "user_instructions": ""
            },
            "internet": {
                "generated_content": "",
                "user_instructions": ""
            },
            "academic_research": {
                "generated_content": "",
                "user_instructions": ""
            }
        },
        "ask": {
            "internet": {
                "generated_content": "",
                "user_instructions": ""
            },
            "general": {
                "generated_content": "",
                "user_instructions": ""
            },
            "academic_research": {
                "generated_content": "",
                "user_instructions": ""
            }
        }
    }
};

export const generated_content = createLocalStorageStore("generated_content", research_initial);


export const prompt_edit_open = writable(false);
export const discrepancy_modal_open = writable(false);
export const source_sentence_modal_open = writable(false);
export const source_sentence_questions_modal_open = writable(false);
export const fact_checks_modal_open = writable(false);
export const discrepancy_modal_questions_open = writable(false);
export const ajust_response_modal_open = writable(false);

export const cookie_consent_given = createLocalStorageStore("cookie_consent_given", false);


export const authenticated_user = createLocalStorageStore("authenticated_user", null);
export const current_user = createLocalStorageStore("current_user", null);


export const file_summary_content = createLocalStorageStore("file_summary_content", "");

export const file_content = createLocalStorageStore("file_content", "");


export const question_prompt_edit_open = writable(false);
export const files_list = createLocalStorageStore('files_list', { "draft": [], "defend": [], "research": [], "review": [], "learn": [], "qualify": [], "notify": [] });
export const selected_file_id = createLocalStorageStore('selected_file_id', 0);

export const eligbility_info = createLocalStorageStore("eligbility_info", { companies: [], selected_company: 0, activity_details: "", innovation_strength: 0, assessment_details: "", });

export const selected_component = createLocalStorageStore('selected_component', "general");
export const selected_question = createLocalStorageStore('selected_question', "");
export const general_prompt = createLocalStorageStore("general_prompt", ``);

export const loading_review = writable(false);


export const review_instructions_modal = writable(false);
export const selected_module = writable("");
export const selected_section = writable("");
export const selected_sub_section = writable("");


export const review_inital = {
    "user_instructions": {
        "baseline": "",
        "advance": "",
        "uncertainty": "",
        "resolution": ""
    },
    "overall": {
        "coherence": {
            "response": "",
            "title": "Coherence",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "risk_factors": {
            "response": "",
            "title": "Risk Factors",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "competent_professionals": {
            "response": "",
            "title": "Competent Professionals",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    },
    "baseline_research": {
        "baseline": {
            "response": "",
            "title": "Baseline",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "internet": {
            "response": "",
            "title": "Internet",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "baseline_research": {
            "response": "",
            "title": "Baseline Research",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    },
    "baseline": {
        "comprehensiveness": {
            "response": "",
            "title": "Comprehensiveness",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "focus": {
            "response": "",
            "title": "Focus",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "phrasing": {
            "response": "",
            "title": "Phrasing",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "guideline_references": {
            "response": "",
            "title": "Guideline References",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "grammar": {
            "response": "",
            "title": "Grammar",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    },
    "advance": {
        "comprehensiveness": {
            "response": "",
            "title": "Comprehensiveness",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "focus": {
            "response": "",
            "title": "Focus",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "phrasing": {
            "response": "",
            "title": "Phrasing",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "guideline_references": {
            "response": "",
            "title": "Guideline References",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "grammar": {
            "response": "",
            "title": "Grammar",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    },
    "uncertainty": {
        "comprehensiveness": {
            "response": "",
            "title": "Comprehensiveness",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "focus": {
            "response": "",
            "title": "Focus",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "phrasing": {
            "response": "",
            "title": "Phrasing",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "guideline_references": {
            "response": "",
            "title": "Guideline References",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "grammar": {
            "response": "",
            "title": "Grammar",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    },
    "resolution": {
        "comprehensiveness": {
            "response": "",
            "title": "Comprehensiveness",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "focus": {
            "response": "",
            "title": "Focus",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "phrasing": {
            "response": "",
            "title": "Phrasing",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "guideline_references": {
            "response": "",
            "title": "Guideline References",
            "loading": false,
            "result_id": "",
            "instructions": ""
        },
        "grammar": {
            "response": "",
            "title": "Grammar",
            "loading": false,
            "result_id": "",
            "instructions": ""
        }
    }
};
export const review = createLocalStorageStore("review", review_inital);




export const qualify_initial = {
    "research": {
        "baseline": {
            "generated_content": "",
            "user_instructions": ""
        },
        "internet": {
            "generated_content": "",
            "user_instructions": ""
        },
        "feedback": {
            "generated_content": "",
            "user_instructions": ""
        }
    },
    "risk_factors": {
        "risk_factors": {
            "generated_content": "",
            "user_instructions": ""
        }
    },
    "comprehensiveness": {
        "baseline": {
            "generated_content": "",
            "user_instructions": ""
        },
        "advance": {
            "generated_content": "",
            "user_instructions": ""
        },
        "uncertainty": {
            "generated_content": "",
            "user_instructions": ""
        },
        "resolution": {
            "generated_content": "",
            "user_instructions": ""
        }
    },
    "questions": {
        "research": {
            "generated_content": "",
            "user_instructions": ""
        },
        "risk_factors": {
            "generated_content": "",
            "user_instructions": ""
        },
        "comprehensiveness": {
            "generated_content": "",
            "user_instructions": ""
        }
    }
};
export const qualify = createLocalStorageStore("qualify", qualify_initial);
export const qualify_general_instructions = createLocalStorageStore("qualify_general_instructions", "");

export const qualify_add_instructions_modal_open = writable(false);
export const qualify_add_general_instructions_modal_open = writable(false);
export const qualify_user_instructions_to_regenerate = createLocalStorageStore("qualify_user_instructions_to_regenerate", "");

export const regenerate_paragraph_modal_open = writable(false);
export const paragraph_to_regenerate = createLocalStorageStore("paragraph_to_regenerate", "");
export const user_instructions_to_regenerate_paragraph = createLocalStorageStore("user_instructions_to_regenerate_paragraph", "");

export const initial_questions = {
    "q_1": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "Project Name",
        "prompt": ``,
        "loading": false,
        "dependencies": [],
        "request_id": "",
        "result_id": ""
    },
    "q_2": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "Main field of science or technology?",
        "prompt": ``,
        "loading": false,
        "dependencies": [],
        "request_id": "",
        "result_id": ""

    },
    "q_3": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "Baseline level of science or technology?",
        "prompt": ``,
        "loading": false,
        "dependencies": [],
        "request_id": "",
        "result_id": ""

    },
    "q_4": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "Advancement in knowledge it aimed to achieve?",
        "prompt": ``,
        "loading": false,
        "dependencies": [],
        "request_id": "",
        "result_id": ""

    },
    "q_5": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "Scientific or technological uncertainties faced?",
        "prompt": ``,
        "loading": false,
        "dependencies": [],
        "request_id": "",
        "result_id": ""

    },
    "q_6": {
        "response": {
            "content": "",
            "sources": [],
            "fact_checks": ""
        },
        "title": "How the project sought to overcome uncertainties?",
        "prompt": ``,
        "loading": false,
        "dependencies": ["q_5"],
        "request_id": "",
        "result_id": ""
    }


};
export const questions = createLocalStorageStore('questions', initial_questions);

export const loading_compile = writable(false);

export const regenerated_paragraph = createLocalStorageStore("regenerated_paragraph", "");



export const initial_defend = {
    "user_instructions": "",
    "q1_1": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q1_2": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q1_3": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q1_4": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q1_5": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q1_6": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q2_1": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q2_2": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q2_3": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q2_4": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q3_0": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q3_1": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q3_2": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q3_3": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_1": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_2": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_3": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_4": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_5": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q4_6": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "q5": {
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": "",
            "loading": false
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    },
    "freeform": {
        "question": "",
        "outline": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        },
        "draft": {
            "generated_content": "",
            "user_instructions": "",
            "result_id": ""
        }
    }
};

export const defend = createLocalStorageStore("defend", initial_defend);
export const loading_defend = writable(false);
export const defend_instructions_modal_open = writable(false);
export const defend_submit_loading = writable(false);


export const loading_project_description = writable(false);
export const project_description_general_prompt_edit = writable(false);
export const project_description_general_prompt = createLocalStorageStore("project_description_general_prompt", "");


export const isDeadlineTabOpen = createLocalStorageStore("isDeadlineTabOpen", true);
export const isDescriptionTabOpen = createLocalStorageStore("isDescriptionTabOpen", false);
