<script>
    import {
        Footer,
        FooterCopyright,
        FooterLink,
        FooterLinkGroup,
        P,
    } from "flowbite-svelte";
</script>

<Footer class="bg-green-50 fixed bottom-0 left-0 right-0  mt-10">
    <div class="mt-5">
        <FooterCopyright
            href="/"
            by="SmartClaim"
            year={2024}
        />
        <FooterLinkGroup
            ulClass="flex flex-wrap items-center justify-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0 ml-5 mb-5"
        >
            <FooterLink href="https://smartclaim.uk/terms"><P size="xs">Terms and Conditions</P></FooterLink>
            <!-- <FooterLink href="/"><P size="sm">Support</P></FooterLink> -->
        </FooterLinkGroup>
    </div>
</Footer>